import { PropsWithChildren } from 'react';
import { useIsCSLite } from '../../utils/useIsCSLite';

export const HideInCSLite = ({ children }: PropsWithChildren) => {
  const isCSLite = useIsCSLite();

  if (isCSLite) {
    return null;
  }

  return children;
};
