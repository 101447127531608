import React, { Component } from 'react';
import Linkify from 'react-linkify';
import { AccordionSummary, MenuItem } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import DisplaySiteTables from './DisplaySiteTables';
import ControlledExpansionPanel from '../../components/ControlledExpansionPanel';
import ColoredLabel from '../../components/ColoredLabel';
import componentStyles from './DisplayOrganization.module.css';
import containerStyles from './Lookup.module.css';
import { ActionsMenu } from '../../components/ActionsMenu';
import { linkifyOpenInNew } from '../../utils/linkifyDecorators';
import {
  MODAL_ADM_ACTIONS,
  MODAL_CONFIRMATION_DIALOG,
  MODAL_MODIFY_PLAN,
  MODAL_MODIFY_PLAN_MAGPIE,
  MODAL_SAMPLING_VISUALIZER,
  MODAL_ADD_SERVICE_PERIOD,
} from '../../components/modals/constants';
import { undeleteSite, clearInterims } from '../Lookup/api';
import ClickToCopy from '../../components/ClickToCopy';
import HasPermission from '../../components/HasPermission';
import { withModalContext } from '../../contexts/ModalContext';
import { withLookupContext } from '../../contexts/LookupContext';
import SwapSitePlan from './actions/SwapSitePlan';
import { getBillingV3Info } from './billingV3.functions';
import SiteBadges from '../../domains/sites/components/SiteBadges';
import { UserContext } from '../../contexts/UserContext';
import { useAccountTrialV2States } from '../../domains/trials/api/useAccountTrialV2States';
import { useCsLiteAccountTrialV2States } from '../../domains/trials/api/useCsLiteAccountTrialV2States';
import { trialStatesIncludesActiveTrials } from '../../domains/trials/utils/trialStatesIncludesActiveTrials';
import { trialStatesIncludesTrialExtensionInProgress } from '../../domains/trials/utils/trialStatesIncludesTrialExtensionInProgress';
import { trialStatesIncludesExtendedTrial } from '../../domains/trials/utils/trialStatesIncludesExtendedTrial';
import { getProductCatalogFromMagpie } from '../../utils/api';
import { camelKeys } from 'js-convert-case';
import { ProvisioningVOCWrapper } from '../ProvisioningVoCInsights';
import { useIsCSLite } from '../../domains/accounts/utils/useIsCSLite';
import ManageTrialMenuItem from '../../domains/trials/components/ManageTrialMenuItem';
import { HideInCSLite } from '../../domains/accounts/components/CsLite/HideInCsLite';

export class DisplaySite extends Component {
  static contextType = UserContext;
  state = {
    popover: {
      open: false,
    },
    ubp: {
      isBlocked: false,
      renewDate: null,
      limit: null,
      usage: null,
    },
    productsCatalog3Y: undefined,
  };

  componentDidMount() {
    if ('URLSearchParams' in window) {
      // Handle automatic modal opening.
      const searchParams = new URLSearchParams(window.location.search);
      // We must have a target site_id or we can't open anything.
      const siteId = searchParams.get('site_id');
      if (siteId && parseInt(siteId, 10) === this.props.site_id) {
        const open = searchParams.get('open');
        this.openModalBySearchParam(open);
      }
    }

    if (this.props.isSiteInUBP) {
      const limit = Number(this.props.monthly_usage.limit);
      const usage = Number(this.props.monthly_usage.usage);

      this.setState({
        ubp: {
          limit,
          usage,
          isBlocked: !(usage < limit),
          renewDate: this.props.monthly_usage.next_reset_date,
        },
      });
    }

    const getPlans = async () => {
      try {
        const plansRes3Y = await getProductCatalogFromMagpie(
          this.props.isCSLite ? 'annually' : 'three_years',
          'eur',
          this.props.productCatalogVersion
        );
        const plansData3Y = await plansRes3Y.json();

        const productsCatalog3Y = plansData3Y
          .map((product) =>
            product.plans.map((plan) =>
              plan.allowances.map((allowance) => {
                return {
                  sku: allowance.sku,
                  allowance:
                    camelKeys(allowance, {
                      recursive: true,
                      recursiveInArray: true,
                    }) ?? [],
                  planName: plan.plan_name,
                  productName: product.product_name,
                  ...(this.props.isCSLite
                    ? {
                        isVoC: product.product_name === 'VoC',
                        isDXA: product.product_name === 'DXA',
                      }
                    : {
                        isAsk: product.product_name === 'Ask',
                        isObserve: product.product_name === 'Observe',
                      }),
                };
              })
            )
          )
          .flat(2);

        this.setState({
          productsCatalog3Y,
        });
      } catch (e) {
        console.log(e);
      }
    };
    getPlans();
  }
  newAdmAction = (newAction) => {
    this.props.adm_actions.push(newAction);
  };

  handleOpenPopover = () => {
    this.setState({
      ...this.props,
      popover: {
        open: true,
      },
    });
  };

  closePopover = () => {
    this.setState({
      ...this.props,
      popover: {
        open: false,
      },
    });
  };

  handleContextModalOpen = (modal, payload) => {
    this.closePopover();
    this.props.modalContext.openModal(modal, payload);

    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);

      if (!searchParams.has('open') || searchParams.get('open') !== modal) {
        searchParams.set('open', modal);
        searchParams.set('site_id', this.props.site_id);
        const newRelativePathQuery =
          window.location.pathname + '?' + searchParams.toString();
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', newRelativePathQuery);
      }
    }
  };

  handlePlanSettingsModalOpen = () => {
    const account = this.props.organization.account;
    const isUbpAccount = account.features.includes(
      'pp_usage_based_pricing_phase_0_variant1'
    );

    if (this.props.magpie) {
      this.handleContextModalOpen(MODAL_MODIFY_PLAN_MAGPIE, {
        account,
        magpie: this.props.magpie,
        siteIds: [this.props.site_id],
      });
    } else {
      this.handleContextModalOpen(MODAL_MODIFY_PLAN, {
        onSuccess: this.props.lookupContext.lookupRequest,
        id: this.props.site_id,
        sample_rate: this.props.sample_rate,
        always_on_rate: this.props.always_on_rate,
        lock_plan: this.props.lock_plan,
        plan_id: this.props.plan_id,
        percentage_discount: this.props.percentage_discount,
        site_ids: [this.props.site_id],
        context: 'single_entity',
        pricing_table: account.pricing_table,
        isUbpAccount,
        has_always_on:
          account.features &&
          account.features.includes('billing.always_on_recordings'),
        entityType: 'site',
        name: this.props.name,
        sites: this.props.sites,
        hasActiveTrialV2: this.props.hasActiveTrialV2,
      });
    }
  };

  handleOnOpenVisualizerModalClick = () => {
    this.handleContextModalOpen(MODAL_SAMPLING_VISUALIZER, {
      id: this.props.site_id,
      name: this.props.name,
    });
  };

  handleActionsModalOpen = () => {
    this.handleContextModalOpen(MODAL_ADM_ACTIONS, {
      data: this.props.adm_actions,
      related_id: this.props.site_id,
      related_type: 'Site',
      newActionCallback: this.newAdmAction,
    });
  };

  onClearInterims = (annotation) =>
    clearInterims(this.props.site_id, annotation);

  handleClearInterimsModalOpen = () => {
    const modalWarning = `Are you sure you want to clear interim plan for Site ID ${this.props.site_id}?`;
    this.handleContextModalOpen(MODAL_CONFIRMATION_DIALOG, {
      title: 'Clear fair downgrade',
      modalWarning: modalWarning,
      onSubmit: this.onClearInterims,
      onSuccess: this.props.lookupContext.lookupRequest,
    });
  };

  modalNameToOpenMethod = {
    [MODAL_ADM_ACTIONS]: this.handleActionsModalOpen,
    [MODAL_CONFIRMATION_DIALOG]: this.handleClearInterimsModalOpen,
    [MODAL_MODIFY_PLAN]: this.handlePlanSettingsModalOpen,
    [MODAL_SAMPLING_VISUALIZER]: this.handleOnOpenVisualizerModalClick,
  };

  resetSearchParams = () => {
    const newRelativePathQuery = window.location.pathname;
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(null, '', newRelativePathQuery);
  };

  openModalBySearchParam = (openValue) => {
    if (openValue && openValue in this.modalNameToOpenMethod) {
      this.modalNameToOpenMethod[openValue]();
    } else {
      this.resetSearchParams();
    }
  };

  handleUndelete = () => {
    if (window.confirm('Are you sure you want to undelete this site?')) {
      const { id } = this.props;
      this.closePopover();
      undeleteSite({ id }).then(() => this.props.lookupContext.lookupRequest());
    }
  };

  handleServicePeriod = () => {
    this.props.modalContext.openModal(MODAL_ADD_SERVICE_PERIOD, {
      siteId: this.props.site_id,
      accountId: this.props.organization.account.id,
    });
  };

  renderSubtitle = () => (
    <span>
      <dt>ID:</dt>{' '}
      <dd>
        <ClickToCopy copyIcon={true}>{this.props.site_id}</ClickToCopy>
      </dd>
    </span>
  );

  render() {
    if (this.props.deleted) {
      return (
        <ControlledExpansionPanel
          defaultExpanded={true}
          className={containerStyles.cardDeleted}
        >
          <AccordionSummary className={containerStyles.cardHeader}>
            <div className={containerStyles.cardHeaderTitles}>
              <span className={containerStyles.cardHeaderMainTitle}>
                {this.props.name}
              </span>
              {this.renderSubtitle()}
            </div>
            <ActionsMenu
              isOpen={this.state.popover.open}
              openPopover={this.handleOpenPopover}
              closePopover={this.closePopover}
            >
              <MenuItem onClick={this.handleUndelete}>Undelete</MenuItem>
            </ActionsMenu>
            <ColoredLabel color="var(--red)">DELETED</ColoredLabel>
          </AccordionSummary>
        </ControlledExpansionPanel>
      );
    } else if (this.props.id) {
      let siteID = this.props.hasAccess ? (
        <React.Fragment>
          <span>
            <dt>ID: &nbsp;</dt>
            <dd>
              <a
                href={`https://insights.hotjar.com/sites/${this.props.site_id}/overview`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.site_id} <OpenInNewIcon className="open-in-new" />
              </a>
            </dd>
          </span>
          <ClickToCopy copyIcon={true} overwriteText={this.props.site_id} />
        </React.Fragment>
      ) : (
        this.renderSubtitle()
      );

      const features = this.props.features || [];
      const migratedBillingV1Site = features.includes(
        'settings.billing_migrated_legacy_account'
      );
      const usesSingleSkuProducts = this.props.products.length === 1;

      const observeIsInFairDowngradeState =
        (this.props.products[0]?.sessions !==
          this.props.products[0]?.effective_sessions ||
          this.props.products[0]?.cc_sessions !==
            this.props.products[0]?.effective_cc_sessions) &&
        this.props.products[0].effective_feature_group !== 'BASIC';
      const askIsInFairDowngradeState =
        this.props.products[1]?.monthly_responses !==
          this.props.products[1]?.effective_monthly_responses &&
        this.props.products[1].effective_feature_group !== 'BASIC';
      const isInFairDowngradeState =
        observeIsInFairDowngradeState || askIsInFairDowngradeState;
      const { billingV3Converted } = getBillingV3Info(
        this.props.magpie,
        this.props.features
      );

      return (
        <ControlledExpansionPanel
          className={containerStyles.card}
          defaultExpanded={true}
          onChange={() => {}}
        >
          <AccordionSummary className={containerStyles.cardHeader}>
            <dl className={containerStyles.cardHeaderTitles}>
              <span className={containerStyles.cardHeaderMainTitle}>
                <dt>Name:</dt>
                <dd>{this.props.name}</dd>
              </span>
              <span className={containerStyles.cardHeaderMainTitle}>
                {siteID}
              </span>
              {this.props.url && this.props.url.length > 0 && (
                <Linkify componentDecorator={linkifyOpenInNew}>
                  <span className={containerStyles.cardHeaderMainTitle}>
                    <dt>URL:</dt>
                    <dd>{this.props.url}</dd>
                  </span>
                </Linkify>
              )}
            </dl>
            {this.props.cms && this.props.cms !== 'unknown' && (
              <ColoredLabel color={'var(--cms)'}>{this.props.cms}</ColoredLabel>
            )}
            {this.props.hasActiveTrialV2 && (
              <ColoredLabel color={'var(--business)'}>
                {`Trial 2.0 ${
                  this.props.isTrialExtensionInProgress ? 'extended' : ''
                }`}
              </ColoredLabel>
            )}
            <HideInCSLite>
              <ActionsMenu
                isOpen={this.state.popover.open}
                openPopover={this.handleOpenPopover}
                closePopover={this.closePopover}
              >
                <MenuItem onClick={this.handleOnOpenVisualizerModalClick}>
                  View sampling rates
                </MenuItem>
                <MenuItem
                  onClick={this.handlePlanSettingsModalOpen}
                  // disabled={this.props.magpie}
                >
                  Modify plan and rates
                </MenuItem>
                {isInFairDowngradeState && (
                  <HasPermission
                    rolesAccepted={['admin', 'billing_management_2']}
                  >
                    <MenuItem onClick={this.handleClearInterimsModalOpen}>
                      Clear Fair Downgrade
                    </MenuItem>
                  </HasPermission>
                )}
                <MenuItem onClick={this.handleActionsModalOpen}>
                  Site Activity
                </MenuItem>
                <SwapSitePlan
                  siteId={this.props.site_id}
                  handleContextModalOpen={this.handleContextModalOpen}
                  disabled={!!this.props.magpie}
                />

                <ManageTrialMenuItem
                  show={!this.props.isCSLite}
                  isCsLite={this.props.isCSLite}
                  modalContext={this.props.modalContext}
                  modalProps={{
                    name: this.props.name,
                    siteId: this.props.site_id,
                    accountId: this.props.organization.account.id,
                    hasActiveTrialV2: this.props.hasActiveTrialV2,
                    trialStates: this.props.trialStates,
                    isTrialExtensionInProgress:
                      this.props.isTrialExtensionInProgress,
                    isTrialExtended: this.props.isTrialExtended,
                    productCatalogVersion: this.props.productCatalogVersion,
                    onCloseModalPopover: this.closePopover,
                  }}
                />

                <ProvisioningVOCWrapper showForCrossSell>
                  <MenuItem onClick={this.handleServicePeriod}>
                    Add service period
                  </MenuItem>
                </ProvisioningVOCWrapper>
              </ActionsMenu>
            </HideInCSLite>
            <div className={componentStyles.icons}>
              <SiteBadges
                products={this.props.products}
                actionsCount={this.props.adm_actions?.length}
                usesSingleSkuProducts={usesSingleSkuProducts}
                migratedBillingV1Site={migratedBillingV1Site}
                handleOnActionsClick={this.handleActionsModalOpen}
                {...(this.props.isSiteInUBP
                  ? { ubpState: this.state.ubp }
                  : {})}
              />
            </div>
          </AccordionSummary>
          <DisplaySiteTables
            cost={this.props.cost}
            created={this.props.created}
            currency={this.props.organization.account.currency}
            dualSKUDiscount={this.props.dual_sku_discount}
            handleOnOpenVisualizerModalClick={
              this.handleOnOpenVisualizerModalClick
            }
            lastData={this.props.last_data}
            lockPlan={this.props.lock_plan}
            nonProfit={this.props.nonprofit_site}
            products={this.props.products}
            recValue={this.props.rec_value}
            isTwoSkusActionRequired={this.props.is_two_skus_action_required}
            billingV3Converted={billingV3Converted}
            billingV3Products={this.props.magpie?.subscription?.products}
            billingV3Costs={this.props.magpie?.current_period_cost_summary}
            siteId={this.props.site_id}
            trialStates={this.props.trialStates}
            isSiteInUBP={this.props.isSiteInUBP}
            isCSLite={this.props.isCSLite}
            productsCatalog={this.state.productsCatalog3Y}
          />
        </ControlledExpansionPanel>
      );
    } else {
      return null;
    }
  }
}

const withHooks = (Component) => {
  return function WrappedComponent(props) {
    const isCSLite = useIsCSLite();
    const site = { ...props, last_data_time: props.last_data };
    const account = props.organization.account;

    const { data: accountTrialStates } = useAccountTrialV2States(
      isCSLite ? undefined : account
    );

    const { data: csLiteAccountTrialStates } = useCsLiteAccountTrialV2States(
      isCSLite ? account : undefined
    );

    // if account is not csLite, all the account's sites trial states were fetched (this is done to optimise requests since it was needed in DisplayAccount anyways)
    // we need to filter all the trial states to find those that belong to the current site being displayed
    const trialStates =
      (isCSLite ? csLiteAccountTrialStates : accountTrialStates)?.filter(
        (trial) =>
          trial.entity.id ===
            String(isCSLite ? props.organization.account_id : site.id) &&
          trial.status !== 'ended'
      ) ?? [];

    const hasActiveTrialV2 = isCSLite
      ? trialStatesIncludesActiveTrials(csLiteAccountTrialStates)
      : trialStatesIncludesActiveTrials(trialStates);

    const isTrialExtensionInProgress =
      trialStatesIncludesTrialExtensionInProgress(trialStates);
    const isTrialExtended = trialStatesIncludesExtendedTrial(trialStates);
    const isSiteInUBP = account.features.includes(
      'pp_usage_based_pricing_phase_0_variant1'
    );

    return (
      <Component
        {...props}
        hasActiveTrialV2={hasActiveTrialV2}
        isTrialExtensionInProgress={isTrialExtensionInProgress}
        isTrialExtended={isTrialExtended}
        trialStates={trialStates}
        isSiteInUBP={isSiteInUBP}
        isCSLite={isCSLite}
        productCatalogVersion={
          props.organization.account?.pricing_table?.product_catalog_version
        }
      />
    );
  };
};

export default withModalContext(withLookupContext(withHooks(DisplaySite)));
